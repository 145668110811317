'use client';
import {
  IMessage,
  TooltipProvider,
  useMessageContext,
  TooltipTrigger,
  TooltipContent,
  Button,
  ContextUserType,
  TMessageAction,
  COMMON_REACTIONS,
  IMessagesQueryParams,
} from '@ui/components';
import { HoverCard, HoverCardContent, HoverCardTrigger, Tooltip } from '@ui/components/ui';
import { Smile, EllipsisVertical, Plus, Flag, SmilePlus } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useClickOutside } from '@ui/hooks';
import { sendGAEvent } from '@next/third-parties/google';

interface IProps {
  message: IMessage;
  contextUserType: ContextUserType;
  query: IMessagesQueryParams;
  triggerScoreAnimation: () => void;
}

export const PostCommentReactionPanel = ({
  message,
  contextUserType,
  query,
  triggerScoreAnimation,
}: IProps) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const messageState = useMessageContext(contextUserType);
  const emojiHoverCardRef = useClickOutside(() => setOpenEmoji(false));
  const moreMenuRef = useClickOutside(() => setOpenMoreMenu(false));

  const onReactToMessage = (emoji: string) => {
    const userReactions = message?.user_reactions?.[messageState.user_id] || [];
    const isRemoving = userReactions.includes(emoji);
    messageState.onReactToMessage(message.id, message.slug, 'reaction', emoji, query);
    // Send GA events
    sendGAEvent('event', 'UserReaction', {
      event: 'emoji_reaction',
      event_action: isRemoving ? 'Remove Emoji' : 'Add Emoji',
      event_label: emoji,
      post_id: message.id,
      user_type: contextUserType,
    });
    !isRemoving && triggerScoreAnimation();
  };

  const showAddEmoji = useMemo(() => {
    if (message?.reaction?.reactions_list) {
      return Object.keys(message?.reaction?.reactions_list).length < 6;
    }
    return false;
  }, [message?.reaction?.reactions_list]);

  return (
    <div className="flex flex-row">
      <PanelTooltip text="React">
        <HoverCard openDelay={0} closeDelay={0} open={openEmoji}>
          {/* {showAddEmoji && ( */}
          <HoverCardTrigger>
            <Button
              type="icon-primary"
              className="h-6 w-6 rounded-sm border-none"
              onClick={() => setOpenEmoji(true)}>
              <SmilePlus className="h-5 w-5 text-black" />
            </Button>
          </HoverCardTrigger>
          {/* )} */}

          <HoverCardContent
            side="top"
            ref={emojiHoverCardRef}
            hideWhenDetached
            className="w-[97vw] rounded-full sm:w-fit z-20"
            style={{ zIndex: 99998 }}>
            <div className="flex w-full items-center justify-center gap-4 sm:gap-1">
              {COMMON_REACTIONS.map((emoji) => (
                <div
                  key={`emoji-${emoji}`}
                  className="hover:bg-inputActive/50 h-7 w-7 rounded-sm border-none bg-transparent text-2xl sm:text-lg"
                  onClick={() => onReactToMessage(emoji)}>
                  {emoji}
                </div>
              ))}
            </div>
          </HoverCardContent>
        </HoverCard>
      </PanelTooltip>
    </div>
  );
};

interface TooltipProps {
  children: React.ReactNode;
  text: string;
}
const PanelTooltip = ({ children, text }: TooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <span>{children}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
