'use client';
import { useState, useEffect } from 'react';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from './constants';
import { IFile } from './types';
import { generateUniqueId } from '@ui/constants';
import { sanitizeFilename } from '@ui/lib/utils';

const initialFileState: IFile = {
  src: '',
  file: null,
  id: '',
  meta: null,
};

export function useFile(callback?: (payload: File | null) => void) {
  const [file, setFileState] = useState<IFile>(initialFileState);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (file.file) {
      setError(null);
    }
  }, [file]);

  function processFile(inputFile: File) {
    const sanitizedFilename = sanitizeFilename(inputFile.name);

    const sanitizedFile = new File([inputFile], sanitizedFilename, {
      type: inputFile.type,
      lastModified: inputFile.lastModified,
    });

    if (!ALLOWED_FILE_TYPES.includes(sanitizedFile.type as any)) {
      setError(`Unsupported file type. Acceptable formats: PNG, JPG, JPEG, GIF.`);
      return null;
    }

    if (sanitizedFile.size > MAX_FILE_SIZE) {
      setError('File size exceeds 10MB. Please upload a smaller file.');
      return null;
    }

    return sanitizedFile;
  }

  function onFileUpload(inputFile: File) {
    const sanitizedFile = processFile(inputFile);
    if (!sanitizedFile) return;

    callback && callback(sanitizedFile);
    const uniqueId = generateUniqueId();
    const src = URL.createObjectURL(sanitizedFile);

    setFileState({
      src,
      file: sanitizedFile,
      id: uniqueId,
      meta: null,
    });
  }

  function onDiscardFile() {
    callback && callback(null);
    setFileState(initialFileState);
  }

  function setFile(inputFile: File | null) {
    if (!inputFile) {
      onDiscardFile();
      return;
    }

    const sanitizedFile = processFile(inputFile);
    if (!sanitizedFile) return;

    callback && callback(sanitizedFile);
    const uniqueId = generateUniqueId();
    const src = URL.createObjectURL(sanitizedFile);

    setFileState({
      src,
      file: sanitizedFile,
      id: uniqueId,
      meta: null,
    });
  }

  return {
    file,
    onFileUpload,
    onDiscardFile,
    setFile,
    error,
  };
}
