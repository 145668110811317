import React from 'react';
import { Edit } from 'lucide-react';

interface PollMenuProps {
  showMenu: boolean;
  isActive: boolean;
  hasVoted?: boolean;
  onEdit: () => void;
  onEndPoll: () => void;
  menuRef: React.RefObject<HTMLDivElement>;
}

export const PollMenu = ({
  showMenu,
  isActive,
  onEdit,
  onEndPoll,
  hasVoted,
  menuRef,
}: PollMenuProps) => {
  if (!showMenu) return null;

  return (
    <div
      ref={menuRef}
      className="absolute top-12 right-4 bg-white shadow-lg rounded-md border border-gray-200 py-1 z-50 min-w-[120px]">
      {!hasVoted && (
        <button
          onClick={onEdit}
          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2">
          <Edit className="h-4 w-4" />
          Edit Poll
        </button>
      )}
      {isActive && hasVoted && (
        <button
          onClick={onEndPoll}
          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 text-red-600 flex items-center gap-2">
          <svg
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
          End Poll
        </button>
      )}
    </div>
  );
};
