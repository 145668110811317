'use client';
import { Title, Note, Text, SourceItem, Icon, IBadgeClaimCreators } from '@ui/components';
import { BadgeSource, BadgeType } from '@ui/constants';
import { twMerge } from 'tailwind-merge';

interface IBadgeClaimInfo {
  badgeNumber: number;
  badgeAmount: number;
  creators: IBadgeClaimCreators;
  platform: BadgeSource;
  publicLink: string;
  isAvailable?: string | boolean;
  currentTab?: string;
  badgeType?: number | null;
}

export function BadgeClaimOutOfStockDetails({
  badgeAmount,
  badgeNumber,
  creators,
  platform,
  publicLink,
  isAvailable,
  currentTab,
  badgeType,
}: IBadgeClaimInfo) {
  function onPublicLink() {
    if (!publicLink) {
      return;
    }
    window.open(publicLink, '_blank');
  }

  return (
    <div className="mb-4 flex w-full flex-col gap-2">
      {creators?.collaboratorName && (
        <div className="bg-input flex flex-col items-center rounded p-3">
          <Note type="m" className="text-customGrey">
            Collaboration with
          </Note>
          <Title type="m">@{creators?.collaboratorName}</Title>
        </div>
      )}
      <div className="bg-input flex flex-col items-center rounded p-3">
        <Note type="m" className="text-customGrey">
          Next Badge Number
        </Note>
        <Title type="mm">Out of Stock</Title>
        {/* <div className="bg-inputActive rounded-full px-2 py-1">
          <Note type="m" className="text-customGrey">
            of {badgeAmount}
          </Note>
        </div> */}
      </div>

      <div className="flex flex-col gap-2">
        <div
          onClick={onPublicLink}
          className={twMerge(
            'bg-input flex basis-1/2 flex-col items-center justify-between gap-2 rounded p-3',
            publicLink ? 'hover:bg-inputActive active:bg-secondaryHover cursor-pointer' : '',
          )}>
          {badgeType === BadgeType.Prize ? (
            <div>
              <Icon type="trophy" />
            </div>
          ) : (
            <div>
              <div className="w-6" />
              <Note type="m" className="text-customGrey">
                Content Source
              </Note>
              <SourceItem source={platform} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
