import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@ui/lib/utils';
import '../components/engagement.css';

interface AnimatedShadowWrapperProps {
  children: React.ReactNode;
  showAnimation: boolean;
  hasBorder?: boolean;
  className?: string;
}

export const AnimatedShadowWrapper: React.FC<AnimatedShadowWrapperProps> = ({
  children,
  showAnimation,
  hasBorder = false,
  className = '',
}) => {
  // Add a mounting state to ensure clean animation starts
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Small delay to ensure styles are properly computed
    const timer = setTimeout(() => {
      setIsMounted(true);
    }, 50);

    return () => {
      clearTimeout(timer);
      setIsMounted(false);
    };
  }, []);

  // Force animation reset when route changes
  useEffect(() => {
    const handleRouteChange = () => {
      setIsMounted(false);
      setTimeout(() => setIsMounted(true), 50);
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  return (
    <motion.div
      className={cn(
        className,
        isMounted && showAnimation && hasBorder && 'circular-gradient-border',
      )}
      // Add key to force remount when animation should restart
      key={`gradient-${isMounted}`}
      initial={{ opacity: 0.99 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 5, repeat: showAnimation ? Infinity : 0 }}>
      {children}
    </motion.div>
  );
};
