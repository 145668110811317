import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cva } from 'class-variance-authority';
import { Trash, GripVertical } from 'lucide-react';
import { ColumnId } from './kanban-board';
import { Button } from '@ui/components/ui/button';

export interface Task {
  id: UniqueIdentifier;
  columnId: ColumnId;
  content: string;
}

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
  onInputChange: (id: UniqueIdentifier, value: string) => void;
  onDeleteItem: (task: Task) => void;
}

export type TaskType = 'Task';

export interface TaskDragData {
  type: TaskType;
  task: Task;
}

export function TaskCard({ task, isOverlay, onDeleteItem, onInputChange }: TaskCardProps) {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: task.id,
    data: {
      type: 'Task',
      task,
    } satisfies TaskDragData,
    attributes: {
      roleDescription: 'Task',
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva('', {
    variants: {
      dragging: {
        over: 'opacity-30',
        overlay: '',
      },
    },
  });

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? 'overlay' : isDragging ? 'over' : undefined,
      })}>
      <div className="flex flex-row gap-1 items-center w-full">
        <Button
          type="button"
          variant="ghost"
          {...attributes}
          {...listeners}
          className="p-1 text-secondary-foreground/50 -ml-2 h-auto cursor-grab">
          <span className="sr-only">Move item</span>
          <GripVertical className="text-gray-400" />
        </Button>

        <div className="flex items-center w-full relative">
          <input
            placeholder="Add item"
            className="w-full outline rounded-[10px] outline-slate-200 focus-visible:outline-slate-200 p-2"
            defaultValue={task.content}
            onChange={(e) => onInputChange(task.id, e.target.value)}
          />

          <Button
            onClick={() => onDeleteItem(task)}
            size="sm"
            type="button"
            variant="ghost"
            className="p-2 text-secondary-foreground/50 mx-2 h-auto cursor-grab absolute right-0 text-red-800">
            <span className="sr-only">Delete item</span>
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
