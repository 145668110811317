'use client';
import { FanProfileUser, IFanProfileUser } from './fan-profile-user/fan-profile-user';
import { FanProfileBadges, IFanProfileBadges } from './fan-profile-badges';

interface IFanProfileProps {
  user: IFanProfileUser;
  badges: IFanProfileBadges;
  creatorsData?: {
    creators: any;
    isCreatorSuccess: boolean;
  };
}
export function FanProfile({ user, badges, creatorsData }: IFanProfileProps) {
  return (
    <div className="relative flex flex-1 flex-col gap-0 pb-16 lg:flex-row lg:gap-8 xl:gap-16">
      <FanProfileUser {...user} />
      <FanProfileBadges {...badges} creatorsData={creatorsData} />
    </div>
  );
}
