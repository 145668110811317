import {
  CheckSquare,
  Code,
  Heading1,
  Heading2,
  Heading3,
  ImageIcon,
  List,
  ListOrdered,
  Text,
  TextQuote,
  Youtube,
  BarChartBig,
} from 'lucide-react';
import { Command, createSuggestionItems, renderItems } from '../../rte-editor/extensions';
import { uploadFn } from './image-upload';
import { ImageUploadOptions } from '../plugins';
// import posthog from 'posthog-js';
import { Editor, Range } from '@tiptap/core';

interface CommandProps {
  editor: Editor;
  range: Range;
}

// const isEnabled = posthog.isFeatureEnabled('Poll');

export const suggestionItems = (onUploadImage: ImageUploadOptions['onUploadImage']) => {
  return createSuggestionItems([
    {
      title: 'Text',
      description: 'Plain paragraph text.',
      searchTerms: ['p', 'paragraph'],
      icon: <Text size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleNode('paragraph', 'paragraph').run();
      },
    },
    {
      title: 'To-do List',
      description: 'For task tracking.',
      searchTerms: ['todo', 'task', 'list', 'check', 'checkbox'],
      icon: <CheckSquare size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleTaskList().run();
      },
    },
    {
      title: 'Heading 1',
      description: 'Big section heading.',
      searchTerms: ['title', 'big', 'large'],
      icon: <Heading1 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run();
      },
    },
    {
      title: 'Heading 2',
      description: 'Medium section heading.',
      searchTerms: ['subtitle', 'medium'],
      icon: <Heading2 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run();
      },
    },
    {
      title: 'Heading 3',
      description: 'Small section heading.',
      searchTerms: ['subtitle', 'small'],
      icon: <Heading3 size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run();
      },
    },
    {
      title: 'Bullet List',
      description: 'Unordered lists.',
      searchTerms: ['unordered', 'point'],
      icon: <List size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    {
      title: 'Numbered List',
      description: 'Ordered lists.',
      searchTerms: ['ordered'],
      icon: <ListOrdered size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
    {
      title: 'Quote',
      description: 'Blockquotes for citations or key phrases.',
      searchTerms: ['blockquote'],
      icon: <TextQuote size={18} />,
      command: ({ editor, range }: CommandProps) =>
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleNode('paragraph', 'paragraph')
          .toggleBlockquote()
          .run(),
    },
    {
      title: 'Code',
      description: 'Snippets of code.',
      searchTerms: ['codeblock'],
      icon: <Code size={18} />,
      command: ({ editor, range }: CommandProps) =>
        editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
    },
    {
      title: 'Image',
      description: 'Upload an image from your computer.',
      searchTerms: ['photo', 'picture', 'media'],
      icon: <ImageIcon size={18} />,
      command: ({ editor, range }: CommandProps) => {
        editor.chain().focus().deleteRange(range).run();
        // upload image
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async () => {
          if (input.files?.length) {
            const file = input.files[0];
            const pos = editor.view.state.selection.from;
            uploadFn(onUploadImage)(file, editor.view, pos);
          }
        };
        input.click();
      },
    },
    {
      title: 'Youtube',
      description: 'Embed a Youtube video.',
      searchTerms: ['video', 'youtube', 'embed'],
      icon: <Youtube size={18} />,
      command: ({ editor, range }: CommandProps) => {
        const videoLink = prompt('Please enter Youtube Video Link') as string;
        //From https://regexr.com/3dj5t
        const ytregex = new RegExp(
          /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
        );

        if (ytregex.test(videoLink)) {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setYoutubeVideo({
              src: videoLink,
            })
            .run();
        } else {
          if (videoLink !== null) {
            alert('Please enter a correct Youtube Video Link');
          }
        }
      },
    },
    {
      title: 'Poll',
      description: 'Create a new poll',
      searchTerms: ['survey', 'vote', 'poll'],
      icon: <BarChartBig />,
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent({
            type: 'poll',
            attrs: { question: '', options: [] },
          })
          .selectParentNode()
          .blur()
          .run();
      },
    },
    // {
    //   title: "Twitter",
    //   description: "Embed a Tweet.",
    //   searchTerms: ["twitter", "embed"],
    //   icon: <Twitter size={18} />,
    //   command: ({ editor, range }) => {
    //     const tweetLink = prompt("Please enter Twitter Link") as string;
    //     const tweetRegex = new RegExp(/^https?:\/\/(www\.)?x\.com\/([a-zA-Z0-9_]{1,15})(\/status\/(\d+))?(\/\S*)?$/);
    //     if (tweetRegex.test(tweetLink)) {
    //       editor
    //         .chain()
    //         .focus()
    //         .deleteRange(range)
    //         .setTweet({
    //           src: tweetLink,
    //         })
    //         .run();
    //     } else {
    //       if (tweetLink !== null) {
    //         alert("Please enter a correct Twitter Link");
    //       }
    //     }
    //   },
    // },
  ]);
};

export const slashCommand = (onUploadImage: ImageUploadOptions['onUploadImage']) =>
  Command.configure({
    suggestion: {
      items: () => suggestionItems(onUploadImage),
      render: renderItems,
    },
  });
