import { DATE_FORMATS } from '@ui/constants';
import { IBadgeDisplay, IBadgeResponse } from './creator.typings';
import dayjs from 'dayjs';

export function formatBadgeResponse(badge: IBadgeResponse): IBadgeDisplay {
  return {
    id: badge._id,
    image: badge.external_url,
    name: badge.name,
    type: badge.type,
    originalId: badge.master_id,
    badgeNumber: badge.serial_number,
    badgeAmount: badge?.total_quantity ?? badge?.quantity,
    availabilityNumber: badge?.availability,
    transferDate: dayjs(badge.updated_at).format(DATE_FORMATS.full),
    color: badge.custom_badge_color,
    collaborator: badge.collaborator,
    author: badge.author,
    platform: badge.media_source,
    badge_owned: badge?.badge_owned,
    serialNumber: badge.serial_number,
    ownedSerialNumber: badge?.owned_serial_number,
    list_of_serials: badge?.list_of_serials,
  };
}
