import { Icon } from '@ui/components';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}
const Loading = (props: Props) => {
  return (
    <div
      className={twMerge(
        'h-full w-full border-none shadow-none focus-visible:outline-none',
        props.className,
      )}>
      <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
        <Icon type="loadingSpinner" className="text-primary h-24 w-24" />
      </div>
    </div>
  );
};

export default Loading;
