import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import PollComponent from './PollComponent';
import { CreatorPollHandlers } from '@cms/services/typings';

export const Poll = (options: CreatorPollHandlers) => {
  return Node.create({
    name: 'poll',
    group: 'block',
    atom: true,
    selectable: false,

    addAttributes() {
      return {
        question: {
          default: '',
        },
        options: {
          default: [
            { id: 'temp-1', text: 'Option 1' },
            { id: 'temp-2', text: 'Option 2' },
          ],
        },
        deletedOptions: {
          default: [],
        },
        pollId: {
          default: null,
        },
        isActive: {
          default: true,
        },
        showResultsBeforeEnd: {
          default: true,
        },
        startDate: {
          default: null,
        },
        endDate: {
          default: null,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: 'div[data-type="poll"]',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      const { question, options, pollId, isActive, showResultsBeforeEnd, ...rest } = HTMLAttributes;
      const serializedOptions = JSON.stringify(options);

      return [
        'div',
        mergeAttributes(
          {
            'data-type': 'poll',
            'data-poll-id': pollId,
            'data-question': question,
            'data-options': serializedOptions,
            'data-is-active': isActive,
            'data-show-results-before-end': showResultsBeforeEnd,
            class: 'poll-container',
          },
          rest,
        ),
      ];
    },

    addNodeView() {
      return ReactNodeViewRenderer((props: any) => <PollComponent {...props} handlers={options} />);
    },
  });
};
