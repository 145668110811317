export const OnboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g filter="url(#filter0_d_3239_163040)">
      <circle
        cx="28"
        cy="28"
        r="25"
        fill="#FBD0B6"
        fill-opacity="0.29"
        shape-rendering="crispEdges"
      />
    </g>
    <path
      d="M32.2329 34.027C32.0176 34.0385 31.8032 33.9915 31.6135 33.8912C31.4238 33.791 31.2661 33.6413 31.1579 33.4589C31.0497 33.2764 30.9951 33.0682 31.0003 32.8573C31.0055 32.6464 31.0702 32.441 31.1873 32.2638L37.4107 22.5885C37.5084 22.4162 37.6501 22.2716 37.8218 22.1686C37.9935 22.0656 38.1896 22.0078 38.3909 22.0007C38.5922 21.9937 38.7919 22.0376 38.9707 22.1283C39.1496 22.2189 39.3014 22.3533 39.4116 22.5183L45.5949 32.2295C45.7167 32.4008 45.7884 32.6014 45.8021 32.8096C45.8159 33.0178 45.7711 33.2257 45.6727 33.4109C45.5743 33.596 45.426 33.7513 45.2439 33.8599C45.0618 33.9685 44.8528 34.0263 44.6395 34.027H32.2329Z"
      stroke="#FF600D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5589 32H15.4265C14.6387 32 14 32.6387 14 33.4265V40.5589C14 41.3467 14.6387 41.9854 15.4265 41.9854H22.5589C23.3467 41.9854 23.9854 41.3467 23.9854 40.5589V33.4265C23.9854 32.6387 23.3467 32 22.5589 32Z"
      stroke="#254941"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 25C25.3137 25 28 22.3137 28 19C28 15.6863 25.3137 13 22 13C18.6863 13 16 15.6863 16 19C16 22.3137 18.6863 25 22 25Z"
      stroke="#F4C261"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_3239_163040"
        x="0"
        y="0"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_3239_163040"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3239_163040" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3239_163040"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
