import { dataQuery } from './leaderboard-api';
import { pollApiService } from '@polls/service';

export const pollApi = pollApiService(dataQuery);

export const {
  useGetPollsQuery,
  useGetPollByIdQuery,
  useLazyGetPollByIdQuery,
  useCreatePollMutation,
  useUpdatePollMutation,
  useDeletePollMutation,
  useVotePollMutation,
  useGetPollResultsQuery,
  useLazyGetPollResultsQuery,
  useEndPollMutation,
} = pollApi;
