'use client';
import React, { useState, useEffect, useRef } from 'react';
import search from '@ui/assets/images/search.png';
import down from '@ui/assets/images/down.png';
import close from '@ui/assets/images/close.png';
import { UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Badge, IGroupForm, IOption, Text } from '@ui/components';

interface ISearchableMultiSelectBaseProps {
  options: IOption[];
  placeholder: string;
  className?: string;
  isFormControlled?: boolean;
  maxSelection?: number;
  optionsFormType?: 'badges' | 'collaborated';
}

interface ISearchableMultiSelectWithFormProps extends ISearchableMultiSelectBaseProps {
  isFormControlled: true;
  setValue: UseFormSetValue<IGroupForm>;
}

interface ISearchableMultiSelectWithFunctionProps extends ISearchableMultiSelectBaseProps {
  isFormControlled: false;
  setValue: (selections: IOption[]) => void;
}

type ISearchableMultiSelectProps =
  | ISearchableMultiSelectWithFormProps
  | ISearchableMultiSelectWithFunctionProps;

export const SearchableMultiSelect = ({
  placeholder,
  options,
  setValue,
  className,
  isFormControlled = true,
  maxSelection,
  optionsFormType = 'badges',
}: ISearchableMultiSelectProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [displayedOptions, setDisplayedOptions] = useState<IOption[]>(options || []);
  const [isOpen, setIsOpen] = useState(false);

  const dropRef = useRef<HTMLDivElement>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Sort by alphabet a-z
  const sortByAlphabet = (items: IOption[]): IOption[] => {
    if (!items) {
      return [];
    }
    return items.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  };

  // Remove or add selections to options and selected list
  const handleOptionSelect = (option: IOption) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
      setDisplayedOptions([...displayedOptions, option]);
    } else {
      if (selectedOptions.length < (maxSelection ?? Infinity)) {
        setSelectedOptions([...selectedOptions, option]);
        setDisplayedOptions(displayedOptions.filter((o) => o !== option));
      }
    }
    // Close the panel if selection is equal to 1
    if (selectedOptions.length + 1 === 1) {
      setIsOpen(false);
    }
  };

  // Handle search on search term change
  useEffect(() => {
    // Function filters out any selected options
    function checkSelectedOptions(options: IOption[]): IOption[] {
      const result = options.filter((option) => {
        return !selectedOptions.some((selected) => selected.value === option.value);
      });
      return result;
    }

    // Search all badges to filter all that includes searchTerm, remove selected badges from displayed options
    if (options && options?.length > 0) {
      const noDraftOptions = options.filter((option) => !option.draft);
      const filteredOptions = noDraftOptions.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      searchTerm.length
        ? setDisplayedOptions(checkSelectedOptions(filteredOptions))
        : setDisplayedOptions(checkSelectedOptions(noDraftOptions));
    }
  }, [searchTerm, options]);

  // Set form value
  useEffect(() => {
    if (isFormControlled) {
      (setValue as UseFormSetValue<IGroupForm>)(
        optionsFormType,
        selectedOptions.map((item) => item.value) || [],
        { shouldValidate: true },
      );
    } else {
      (setValue as (selections: IOption[]) => void)(selectedOptions || []);
    }
  }, [selectedOptions]);

  // Close select options when user clicks outside of element
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={twMerge('relative w-full max-w-[43vw]', className)}>
      <div className="relative mb-[16px] flex w-full items-center rounded-sm bg-[#F7F7F8] py-[16px] pl-[56px] pr-[16px]">
        <img src={search.src} alt="search" className="absolute left-[16px] top-[30%]" />
        <img src={down.src} alt="search" className="absolute right-[16px] top-[30%]" />
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
          className="w-full bg-transparent outline-none"
          onClick={() => setIsOpen(true)}
        />
      </div>
      {isOpen && (
        <div
          className="absolute top-[8vh] z-10 max-h-[32vh] w-full overflow-y-auto rounded-sm bg-[#F7F7F8] p-1 shadow-md"
          ref={dropRef}>
          {displayedOptions.length > 0 ? (
            sortByAlphabet(displayedOptions).map((option, idx) => (
              <div
                key={option.value + idx}
                className={twMerge(
                  'flex items-center rounded-sm bg-[#F7F7F8] px-[8px] py-[12px] transition duration-500',
                  selectedOptions.length >= (maxSelection ?? Infinity)
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:bg-[#ededee]',
                )}
                onClick={() =>
                  selectedOptions.length < (maxSelection ?? Infinity) && handleOptionSelect(option)
                }>
                <Badge
                  color={option?.color}
                  size="xxxs"
                  image={option?.image}
                  type={option?.type}
                  showSerialNumber={false}
                />
                <Text type="s" className="ml-[8px] font-[600]">
                  {option.label}
                </Text>
              </div>
            ))
          ) : (
            <Text type="xs" className="w-full py-2 text-center text-[#757576]">
              Nothing found
            </Text>
          )}
        </div>
      )}
      <div className="w-full">
        <div className="flex w-full overflow-x-auto">
          {selectedOptions.map((option, idx) => (
            <div
              key={option.value + idx}
              className="mr-[16px] flex min-w-fit items-center rounded-sm bg-[#F7F7F8] px-[12px] py-[8px]">
              <Badge
                color={option?.color}
                size="xxxs"
                image={option?.image}
                type={option?.type}
                showSerialNumber={false}
              />
              <Text type="s" className="ml-[8px] font-[600]">
                {option.label}
              </Text>
              <img
                src={close.src}
                alt="search"
                className="ml-[8px] h-3 w-3 cursor-pointer"
                onClick={() => handleOptionSelect(option)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
