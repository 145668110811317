import { IPaymentTransaction } from '@monetization/service';
import { EmptyScreen, LoadingSkeleton, PaymentTransactionItem } from '@ui/components';

interface Props {
  transactions: IPaymentTransaction[];
  isLoading: boolean;
  emptyStateMessage: string;
  showEmptyState: boolean;
  user_id: string;
  className?: string;
}
export const TransactionList = (props: Props) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        {props.transactions.map((transaction, index) => (
          <PaymentTransactionItem
            key={transaction._id}
            index={index + 1}
            user_id={props.user_id}
            transaction={transaction}
            className={props.className}
          />
        ))}
      </div>

      {props.isLoading && (
        <div className="flex flex-col gap-4">
          {Array.from(Array(3).keys()).map((key) => (
            <div key={key} className="flex flex-col gap-2 rounded-sm justify-between bg-input p-4">
              <LoadingSkeleton className="h-3 w-20" />
              <LoadingSkeleton className="h-4 w-full max-w-80" />
            </div>
          ))}
        </div>
      )}

      {props.showEmptyState && !props.isLoading && (
        <EmptyScreen title="" message={props.emptyStateMessage} className="!bg-transparent py-32" />
      )}
    </>
  );
};
