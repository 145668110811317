import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { IPoll, IPollQueryParams, ICreatePoll, IVotePoll, IPollResults } from './typings';

export const pollApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'poll-api',
    baseQuery,
    tagTypes: ['Polls'],
    endpoints: (builder) => ({
      // Get all polls with optional filtering
      getPolls: builder.query<{ polls: IPoll[] }, IPollQueryParams>({
        query: (params) => ({
          url: 'public/v1/polls',
          params,
        }),
        providesTags: ['Polls'],
        transformResponse: (response: { polls: IPoll[] }) => {
          return {
            polls: response.polls,
          };
        },
      }),

      // Get single poll by ID
      getPollById: builder.query<IPoll, { id: string; user_id: string }>({
        query: ({ id, user_id }) => ({
          url: `public/v1/polls/${id}`,
          params: { user_id },
        }),
        providesTags: (result, error, { id }) => [{ type: 'Polls', id }],
        transformResponse: (response: { poll: IPoll }) => response.poll,
      }),

      // Create new poll
      createPoll: builder.mutation<IPoll, ICreatePoll>({
        query: (pollData) => ({
          url: 'public/v1/polls',
          method: 'POST',
          body: { poll: pollData },
        }),
        transformResponse: (response: { poll: IPoll }) => response.poll,
        invalidatesTags: ['Polls'],
      }),

      // Update poll
      updatePoll: builder.mutation<IPoll, { id: string; poll: Partial<ICreatePoll> }>({
        query: ({ id, poll }) => ({
          url: `public/v1/polls/${id}`,
          method: 'PATCH',
          body: { poll },
        }),
        transformResponse: (response: { poll: IPoll }) => response.poll,
        invalidatesTags: (result, error, { id }) => [{ type: 'Polls', id }],
      }),

      // Delete poll
      deletePoll: builder.mutation<void, string>({
        query: (id) => ({
          url: `public/v1/polls/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Polls'],
      }),

      // Submit a vote
      votePoll: builder.mutation<IPoll, IVotePoll>({
        query: ({ poll_id, user_id, option_id }) => ({
          url: `public/v1/polls/${poll_id}/vote`,
          method: 'POST',
          body: { user_id, option_id },
        }),
        transformResponse: (response: { poll: IPoll }) => response.poll,
        invalidatesTags: (result, error, { poll_id }) => [{ type: 'Polls', id: poll_id }],
        async onQueryStarted({ poll_id, ...rest }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedPoll } = await queryFulfilled;

            // Update the poll in the cache
            dispatch(
              api.util.updateQueryData(
                'getPollById',
                { id: poll_id, user_id: rest.user_id },
                (draft) => {
                  return updatedPoll;
                },
              ),
            );
          } catch {
            // If the mutation fails, we don't need to do anything
          }
        },
      }),

      // Get poll results
      getPollResults: builder.query<IPollResults, { id: string; user_id: string }>({
        query: ({ id, user_id }) => ({
          url: `public/v1/polls/${id}/results`,
          params: { user_id },
        }),
        transformResponse: (response: { results: IPollResults }) => response.results,
      }),

      // End poll early
      endPoll: builder.mutation<IPoll, { id: string; user_id: string }>({
        query: ({ id, user_id }) => ({
          url: `public/v1/polls/${id}/end_poll`,
          method: 'POST',
          body: { user_id },
        }),
        transformResponse: (response: { poll: IPoll }) => response.poll,
        invalidatesTags: (result, error, { id }) => [{ type: 'Polls', id }],
      }),
    }),
  });

  return api;
};
