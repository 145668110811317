import { Icon, Note, Title, CustomImage } from '../atoms';
import korcrewLogo from '@ui/assets/images/Logo-text-dark.png';
interface IAuthImage {
  title: string;
  subtitle: string;
}

export function AuthImage({ title, subtitle }: IAuthImage) {
  return (
    <div className={`h-full w-full lg:p-4 lg:pr-0`}>
      <div className="flex h-full justify-center bg-[#FFC5A6] px-4 lg:flex lg:flex-col lg:justify-between lg:rounded-3xl lg:px-14 lg:py-16">
        <img
          src={korcrewLogo.src}
          alt="Korcrew"
          className=" w-[240px] mb-[5.2rem] mt-[6rem] pt  lg:m-0 lg:w-[140px]"
        />

        <div className="hidden w-full justify-center lg:flex">
          <CustomImage type="login" className="max-w-lg" />
        </div>
        <div className="hidden  lg:block">
          <Title type="mm">{title}</Title>
          <Note type="xl">{subtitle}</Note>
        </div>
      </div>
    </div>
  );
}
