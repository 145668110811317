import { twMerge } from 'tailwind-merge';
import {
  Button,
  Avatar,
  Icon,
  LoadingSkeleton,
  Text,
  FormImage,
  MobileHeader,
} from '@ui/components/atoms';
import { BadgeImageInfo } from '@ui/components/moleculas/badge-image-info';
import { IBadgeClaimState, IBadgeClaimType } from '../badge-claim.typings';
import { badgeClaimClassName } from '../badge-claim.style';
import { MobileHeaderBackButton } from '@ui/components/moleculas';
import { BadgeType } from '@ui/constants';
import { useState } from 'react';
import korcrewLogo from '@ui/assets/images/Logo-text-dark.png';

interface IBadgeClaimImage {
  avatar?: string;
  showAvatar?: boolean;
  name: string;
  image: string;
  caption: string;
  type: BadgeType;
  color: string;
  claimType: IBadgeClaimType;
  badgeState: IBadgeClaimState;
  badgeNumber: number;
  badgeAmount?: number;
  perks: string;
  onBack?(): void;
  gotoForum?(): void;
  creatorHasCommunity?: boolean;
  onBadgeLeaderboard?(): void;
  badgeType?: number | null;
  shouldBeGrey?: boolean;
  isLoggedIn?: string | null;
  newPostNotifications: any;
  badgeInChallenge?: boolean;
  openChallenge?(): void;
  hasAchieved?: boolean;
}

export function BadgeClaimImage({
  name,
  avatar,
  showAvatar,
  caption,
  image,
  type,
  color,
  claimType,
  badgeState = 'default',
  badgeNumber,
  perks,
  badgeAmount,
  shouldBeGrey,
  isLoggedIn,
  onBack,
  onBadgeLeaderboard,
  gotoForum,
  badgeType,
  newPostNotifications,
  creatorHasCommunity,
  badgeInChallenge,
  openChallenge,
  hasAchieved,
}: IBadgeClaimImage) {
  const [showSpinner, setShowSpinner] = useState(true);
  const textParamMap: Record<string, { text: string }> = {
    default: { text: 'Collect Badge to Unlock Perks' },
    collected: { text: 'Badge Perks' },
    'out-of-stock': { text: 'Collect Badge to Unlock Perks' },
    'already-collected': {
      text: 'Badge Perks',
    },
  };

  const handleImageLoad = () => {
    setShowSpinner(false);
  };
  const isPrizeBadge = badgeType === BadgeType.Prize && !hasAchieved;

  const className = badgeClaimClassName[claimType];
  return (
    <FormImage
      className={className.container.image}
      internalClassName={className.container.imageInternal}>
      <MobileHeader className={className.badgeImage.header}>
        <MobileHeaderBackButton showButton={isLoggedIn !== null ? true : false} onBack={onBack} />
        <img src={korcrewLogo.src} alt="Korcrew" className="w-[110px]" />
        <Avatar size="sm" src={avatar} className={showAvatar ? '' : 'invisible'} />
      </MobileHeader>
      {badgeState === 'loading' ? (
        <div className="flex h-full flex-col items-center justify-center py-4">
          <LoadingSkeleton className="mb-6 aspect-square w-48 lg:w-64" />
          <LoadingSkeleton className="mb-4 h-12 w-64" />
          <LoadingSkeleton className="h-10 w-60" />
        </div>
      ) : (
        <div
          className={twMerge(
            'flex h-full flex-1 flex-col items-center justify-center',
            className.badgeImage.container,
          )}>
          <div
            className={`${
              (badgeState === 'go-to-content' || badgeState === 'out-of-stock' || shouldBeGrey) &&
              'opacity-[0.6] grayscale'
            }`}>
            <BadgeImageInfo
              name={name}
              caption={caption}
              image={image}
              type={type}
              color={color}
              badgeAmount={badgeAmount}
              size={claimType === 'default' ? 'xl' : 'l'}
              badgeNumber={badgeNumber}
              showSpinner={showSpinner}
              onImageLoad={handleImageLoad}
            />
          </div>
          <div className={'flex w-full flex-col items-center justify-center gap-4 pb-8 lg:pb-12'}>
            <div className={twMerge('max-w-sm px-6', perks ? '' : 'hidden')}>
              <Text type="m" className="mb-2 text-center font-semibold">
                {textParamMap[badgeState]?.text}
              </Text>
              <div className="min-w-[300px] rounded-md bg-transparent bg-gradient-to-br from-[#8F00FF] to-[#FFA500] p-0.5">
                <div className="h-full w-full max-w-sm rounded-[14px] bg-white p-4">
                  <Text type="m">{perks}</Text>
                </div>
              </div>
              {badgeInChallenge && !isPrizeBadge && (
                <div
                  className="flex items-center justify-center flex-col mt-4 mb-4 w-full whitespace-nowrap text-md"
                  onClick={openChallenge}>
                  <span>This badge belongs to a challenge</span>
                  <div className="flex items-center justify-center mt-2">
                    <Icon
                      type="challenge"
                      stroke="#FF600D"
                      className="mr-1 w-5 h-5 md:w-6 md:h-6"
                    />{' '}
                    <span className="text-sm md:text-md font-semibold cursor-pointer text-primary">
                      View challenge
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div
              className={twMerge(
                'flex w-full justify-center gap-3 lg:hidden',
                onBadgeLeaderboard ? '' : 'hidden',
                className.badgeImage.buttons,
              )}>
              <Button type="secondary" onClick={onBadgeLeaderboard}>
                <Icon type="leaderboard" className="mr-3" /> Ranking
              </Button>
              {creatorHasCommunity && (
                <div className="relative w-fit max-w-[300px]">
                  {newPostNotifications && newPostNotifications?.unread_count > 0 && (
                    <div className="w-6 h-6 p-2 rounded-full bg-primary text-white absolute top-0 right-0 flex items-center justify-center text-xs">
                      {newPostNotifications?.unread_count}
                    </div>
                  )}
                  <Button
                    type="primary"
                    className="max-w-[45vw] text-sm lg:text-lg bg-black hover:bg-black/95"
                    onClick={gotoForum}>
                    <Icon type="community" className="mr-3" /> Community
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </FormImage>
  );
}
