import { notificationApiService } from '@notification/service';
import { dataQuery } from './leaderboard-api';
export const notificationApi = notificationApiService(dataQuery);
export const {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useGetNotificationByTypeQuery,
  useMarkNotificationsByTypeMutation,
  useGetNotificationPreferenceQuery,
  useLazyGetNotificationPreferenceQuery,
  useUpdateNotificationPreferenceMutation,
} = notificationApi;
