import { NodeViewWrapper, type NodeViewProps } from '@tiptap/react';
import { Button } from '@ui/components/atoms';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { MoreVertical, Trash, Plus, X, ChevronLeft } from 'lucide-react';
import { CreatorPollHandlers } from '@cms/services/typings';
import { PollMenu } from './components/PollMenu';
import { PollResults } from './components/PollResults';
import { ConfirmationDialog } from './components/ConfirmationDialog';

const QUESTION_CHAR_LIMIT = 200;
const OPTION_CHAR_LIMIT = 65;

interface PollComponentProps extends NodeViewProps {
  handlers: CreatorPollHandlers;
}
interface PollResults {
  options: Array<{
    option_id: string;
    text: string;
    percentage: number;
  }>;
}

const PollComponent = ({ node, updateAttributes, deleteNode, handlers }: PollComponentProps) => {
  const [isEditing, setIsEditing] = useState(!node.attrs.pollId);
  const [isLoading, setIsLoading] = useState(false);
  const [pollResults, setPollResults] = useState<PollResults | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showEndPollModal, setShowEndPollModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeletingPoll, setIsDeletingPoll] = useState(false);
  const [isEndingPoll, setIsEndingPoll] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const isSaveDisabled = useMemo(() => {
    const hasEmptyOptions = (node.attrs.options || []).some(
      (option: any) => !option.text || option.text.trim() === '',
    );
    const hasInsufficientOptions = (node.attrs.options || []).length < 2;
    const hasNoQuestion = !node.attrs.question || node.attrs.question.trim() === '';

    return hasEmptyOptions || hasInsufficientOptions || hasNoQuestion;
  }, [node.attrs.options, node.attrs.question]);

  useEffect(() => {
    const fetchPollResults = async () => {
      if (node.attrs.pollId && handlers.getPollResults) {
        try {
          const results = await handlers.getPollResults(node.attrs.pollId);
          setPollResults(results);
        } catch (error) {
          console.error('Error fetching poll results:', error);
        }
      }
    };

    fetchPollResults();
  }, [node.attrs.pollId, handlers]);

  const savePoll = useCallback(async () => {
    try {
      setIsLoading(true);
      const pollData = {
        question: node.attrs.question,
        poll_options_attributes: [
          // Include current options
          ...(node.attrs.options || []).map((option: any) => ({
            text: option.text,
            id: option.id,
            _destroy: false,
          })),
          // Include deleted options with _destroy flag
          ...((node.attrs.deletedOptions || []).flat().map((option: any) => ({
            id: option.id,
            _destroy: true,
          })) || []),
        ],

        creator_id: handlers.userId,
        start_date: new Date().toISOString(),
        show_results_before_end: true,
        pollable_type: 'Notebook',
        pollable_id: handlers?.notebook_id || '',
      };

      let response;

      if (node.attrs.pollId) {
        response = await handlers.onUpdatePoll!(node.attrs.pollId, pollData);
      } else {
        response = await handlers.onSavePoll!(pollData);
      }

      updateAttributes({
        pollId: response?.id,
        isActive: response?.is_active,
        startDate: response?.start_date,
        endDate: response?.end_date,
        options: response?.options?.map((option: any) => ({
          id: option.id,
          text: option.text,
          position: option.position,
        })),
        deletedOptions: [],
      });

      if (response?.id && handlers.getPollResults) {
        const results = await handlers.getPollResults(response.id);
        setPollResults(results);
      }

      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save poll:', error);
    } finally {
      setIsLoading(false);
    }
  }, [node.attrs, handlers, updateAttributes]);

  const handleEndPoll = async () => {
    if (!node.attrs.pollId) return;

    try {
      setIsEndingPoll(true);
      await handlers.endPoll!(node.attrs.pollId);
      updateAttributes({ isActive: false });

      const results = await handlers.getPollResults(node.attrs.pollId);
      setPollResults(results);
    } catch (error) {
      console.error('Error ending poll:', error);
    } finally {
      setIsEndingPoll(false);
      setShowEndPollModal(false);
    }
  };

  const handleDeletePoll = async () => {
    try {
      setIsDeletingPoll(true);
      if (node.attrs.pollId) {
        await handlers.onDeletePoll!(node.attrs.pollId);
      }
      deleteNode();
    } catch (error) {
      console.error('Error deleting poll:', error);
    } finally {
      setIsDeletingPoll(false);
      setShowDeleteModal(false);
    }
  };

  const addOption = useCallback(() => {
    const options = [...(node.attrs.options || [])];
    options.push({
      text: '',
    });
    updateAttributes({ options });
  }, [node.attrs.options, updateAttributes]);

  const removeOption = useCallback(
    (indexToRemove: number) => {
      const options = [...(node.attrs.options || [])];
      options[indexToRemove] = {
        ...options[indexToRemove],
        _destroy: true,
      };
      const displayOptions = options.filter((option) => !option._destroy);
      updateAttributes({
        options: displayOptions,
        deletedOptions: [...node.attrs.deletedOptions, options.filter((option) => option._destroy)],
      });
    },
    [node.attrs.options, updateAttributes],
  );

  const updateOption = useCallback(
    (index: number, text: string) => {
      if (text.length <= OPTION_CHAR_LIMIT) {
        const options = [...(node.attrs.options || [])];
        options[index] = {
          ...options[index],
          text,
        };
        updateAttributes({ options });
      }
    },
    [node.attrs.options, updateAttributes],
  );

  const handleQuestionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;
      if (text.length <= QUESTION_CHAR_LIMIT) {
        updateAttributes({ question: text });
      }
    },
    [updateAttributes],
  );

  if (isEditing) {
    return (
      <NodeViewWrapper className="my-4 p-12 border border-primaryHover rounded-sm relative select-none">
        <Trash
          className="h-4 w-4 text-red-500 absolute top-6 right-6 cursor-pointer"
          onClick={() => {
            setShowDeleteModal(true);
            setShowMenu(false);
          }}
        />
        <ChevronLeft
          className="h-6 w-6 absolute top-6 left-4 cursor-pointer"
          onClick={() => setIsEditing(false)}
        />

        <div className="w-full mb-[25px] mt-4">
          <div className="relative">
            <input
              type="text"
              value={node.attrs.question || ''}
              onChange={handleQuestionChange}
              placeholder="Add poll question"
              className="flex-1 w-full h-[60px] p-2 border rounded-sm border-primaryLight font-bold text-[20px] pl-5"
              maxLength={QUESTION_CHAR_LIMIT}
            />
            <div className="absolute right-2 bottom-2 text-xs text-gray-500">
              {(node.attrs.question || '').length}/{QUESTION_CHAR_LIMIT}
            </div>
          </div>
        </div>

        <div className="space-y-6">
          {(node.attrs.options || []).map((option: any, index: number) => (
            <div key={option.id} className="flex items-center relative">
              <div className="relative w-full">
                <input
                  type="text"
                  value={option.text}
                  onChange={(e) => updateOption(index, e.target.value)}
                  placeholder={`Option ${index + 1}`}
                  className="flex-1 w-full h-[60px] p-2 border border-primaryLight rounded-sm text-[14px] pl-5"
                  maxLength={OPTION_CHAR_LIMIT}
                />
                <div className="absolute right-2 bottom-2 text-xs text-gray-500">
                  {option.text.length}/{OPTION_CHAR_LIMIT}
                </div>
              </div>
              {(node.attrs.options || []).length > 2 && node.attrs.isActive && (
                <X
                  className="w-5 h-5 absolute -right-6 top-5 cursor-pointer text-primary"
                  onClick={() => removeOption(index)}
                />
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between mt-[45px]">
          {node.attrs.isActive && (
            <Button
              onClick={addOption}
              size="regular"
              type="outline"
              disabled={node.attrs.options?.length > 3}
              className="flex items-center rounded-md border-primaryActive gap-1 font-semibold py-0 h-[40px] text-[15px]">
              <Plus className="h-4 w-4" />
              Add Options
            </Button>
          )}

          {node.attrs.isActive && (
            <Button
              onClick={savePoll}
              disabled={isSaveDisabled}
              size="regular"
              type="outline"
              isLoading={isLoading}
              className="h-[40px] text-white bg-primaryHover hover:text-black">
              Save Poll
            </Button>
          )}
        </div>

        <ConfirmationDialog
          isOpen={showDeleteModal}
          title="Delete this poll?"
          description="This action cannot be undone. Once deleted, the poll and all its responses will be permanently removed."
          confirmText="Delete Poll"
          isLoading={isDeletingPoll}
          onConfirm={handleDeletePoll}
          onCancel={() => setShowDeleteModal(false)}
          confirmButtonClassName="bg-red-600 hover:bg-red-700 text-white"
        />
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper
      className={`poll-view my-4 pb-12 pt-8 px-12 border border-primaryHover rounded-sm relative select-none ${
        !node.attrs.isActive && 'opacity-60'
      }`}>
      <MoreVertical
        className="h-4 w-4 absolute top-6 right-4 cursor-pointer"
        onClick={() => setShowMenu(!showMenu)}
      />
      <PollMenu
        showMenu={showMenu}
        isActive={node.attrs.isActive}
        hasVoted={!!pollResults?.options?.length}
        onEdit={() => {
          setIsEditing(true);
          setShowMenu(false);
        }}
        onEndPoll={() => {
          setShowEndPollModal(true);
          setShowMenu(false);
        }}
        menuRef={menuRef}
      />

      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold text-[16px]">{node.attrs.question}</h3>
      </div>

      <div className="space-y-6">
        {pollResults?.options?.length ? (
          <PollResults options={pollResults.options} />
        ) : (
          (node.attrs.options || []).map((option: any) => (
            <div
              key={option.id}
              className="p-2 pl-5 flex items-center justify-start text-[14px] border border-primaryLight h-[60px] rounded-md hover:bg-gray-50">
              {option.text}
            </div>
          ))
        )}
      </div>
      {!node.attrs.isActive && <div className="text-note mt-4 text-sm">Poll has ended</div>}

      <ConfirmationDialog
        isOpen={showEndPollModal}
        title="End this poll?"
        description="This action cannot be undone. Once ended, users will no longer be able to vote, and results will be visible to everyone."
        confirmText="End Poll"
        isLoading={isEndingPoll}
        onConfirm={handleEndPoll}
        onCancel={() => setShowEndPollModal(false)}
        confirmButtonClassName="bg-primary hover:bg-primaryHover"
      />
    </NodeViewWrapper>
  );
};

export default PollComponent;

PollComponent.displayName = 'PollComponent';
