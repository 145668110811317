import React, { useState, useMemo } from 'react';
import { Button, Icon, Separator } from '@ui/components';
import { Settings } from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from '@ui/components/ui/sheet';
import { useMobile } from '@ui/hooks';
import { PopoverContentProps } from '@radix-ui/react-popover';
import {
  INotification,
  INotificationQueryParam,
  notificationAdapter,
  notificationSelector,
} from '@notification/service';
import { EntityState } from '@reduxjs/toolkit';
import { NotificationWrapper } from './notification-wrapper';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@ui/components/ui/dropdown-menu';
import { useNotificationPanelState } from './notification-panel-context';

interface Props {
  children: (unreadCount: number) => React.ReactNode;
  side?: PopoverContentProps['side'];
  useGetNotificationsQuery: any;
  useUpdateNotificationMutation: any;
  user_id: string;
  showSettings?: boolean;
  onSettings?: () => void;
}
export const NotificationPanelContent = React.memo(
  ({
    children,
    side,
    useGetNotificationsQuery,
    user_id,
    useUpdateNotificationMutation,
    showSettings,
    onSettings,
  }: Props) => {
    const [updateNotification] = useUpdateNotificationMutation();
    const { isOpen, openNotificationPanelState, closeNotificationPanelState } =
      useNotificationPanelState();
    const [query, setQuery] = useState<INotificationQueryParam>({ page: 1, pageSize: 20, user_id });
    const isMobile = useMobile('(max-width: 800px)');
    const { data, isSuccess } = useGetNotificationsQuery(
      { ...query, user_id },
      {
        refetchOnFocus: true,
        skip: !user_id,
        selectFromResult: ({
          data,
          ...params
        }: {
          data: { notifications: EntityState<INotification, string> };
        }) => {
          return {
            data: {
              ...data,
              notifications: notificationSelector.selectAll(
                data ? data.notifications : notificationAdapter.getInitialState(),
              ),
            },
            ...params,
          };
        },
      },
    );

    const onNextPage = () => setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
    const hasMorePages = useMemo(
      () => (data ? query.page < data.total_pages : false),
      [data, query.page, isSuccess],
    );

    const unreadCount = useMemo(() => {
      const count = data?.unread_notifications || '';
      return count > 99 ? '99+' : count.toString();
    }, [data]);

    const onUpdateNotification = async (notification: Partial<INotification>) => {
      try {
        await updateNotification({
          ...notification,
          queryParams: { ...query, user_id: notification.user_id },
        });
      } catch (error) {}
    };

    return (
      <>
        {isMobile ? (
          <Sheet
            open={isOpen}
            onOpenChange={(open) =>
              open ? openNotificationPanelState() : closeNotificationPanelState()
            }>
            <SheetTrigger asChild>
              <span>{children(unreadCount)}</span>
            </SheetTrigger>
            <SheetContent closeButtonClassName="hidden" className="w-full h-full p-0 m-0">
              <SheetHeader className="space-y-0">
                <SheetTitle></SheetTitle>
                <SheetDescription> </SheetDescription>
              </SheetHeader>
              <NotificationWrapper
                isSuccess={isSuccess}
                notifications={data?.notifications || []}
                onNextPage={onNextPage}
                hasMorePages={hasMorePages}
                onUpdateNotification={onUpdateNotification}>
                <PanelHeader
                  CloseElement={SheetClose}
                  showSetting={showSettings}
                  onSettings={onSettings}
                />
              </NotificationWrapper>
            </SheetContent>
          </Sheet>
        ) : (
          <DropdownMenu
            open={isOpen}
            onOpenChange={(open) =>
              open ? openNotificationPanelState() : closeNotificationPanelState()
            }>
            <DropdownMenuTrigger asChild>
              <span>{children(unreadCount)}</span>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="p-0 m-0 rounded-sm border-none h-full w-full min-w-[400px]"
              style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
              side={side}
              hideWhenDetached>
              <NotificationWrapper
                onUpdateNotification={onUpdateNotification}
                isSuccess={isSuccess}
                notifications={data?.notifications || []}
                onNextPage={onNextPage}
                hasMorePages={hasMorePages}>
                <PanelHeader showSetting={showSettings} onSettings={onSettings} />
              </NotificationWrapper>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </>
    );
  },
);

NotificationPanelContent.displayName = 'NotificationPanelContent';

interface PanelHeaderProps {
  CloseElement?: React.ElementType;
  showSetting?: boolean;
  onSettings?: () => void;
}
const PanelHeader = React.memo(
  ({ CloseElement, showSetting = true, onSettings }: PanelHeaderProps) => {
    const { closeNotificationPanelState } = useNotificationPanelState();

    return (
      <div className="flex-none">
        <div className="flex flex-row justify-between items-center p-0 pr-1">
          <div className="flex items-center gap-2 h-12 w-12 lg:h-12 lg:w-12">
            {CloseElement && (
              <CloseElement asChild>
                <Button
                  type="icon"
                  size="smallIcon"
                  className="lg:hidden border-none h-12 w-12 lg:h-12 lg:w-12"
                  onClick={closeNotificationPanelState}>
                  <Icon type="back" />
                </Button>
              </CloseElement>
            )}
            <p className="font-medium text-md pl-4">Notifications</p>
          </div>
          {showSetting && (
            <Button
              type="icon"
              size="smallIcon"
              className="outline-none border-none h-12 w-12 lg:h-12 lg:w-12 invisible"
              onClick={onSettings}>
              <Settings className="h-4 w-4" style={{ strokeWidth: 1 }} />
            </Button>
          )}
        </div>
        <Separator className="m-0 p-0 bg-slate-100" />
      </div>
    );
  },
);

PanelHeader.displayName = 'PanelHeader';
