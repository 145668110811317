interface PollResultsProps {
  options: Array<{
    option_id: string;
    text: string;
    percentage: number;
  }>;
}

export const PollResults = ({ options }: PollResultsProps) => {
  if (!options?.length) return null;

  return (
    <div className="space-y-2">
      {options.map((option) => (
        <div key={option.option_id} className="relative mb-2">
          <div className="p-2 pl-5 flex items-center justify-between text-[14px] font-bold border border-primaryActive h-[50px] rounded-[12px] overflow-hidden">
            <div className="flex items-center gap-2 z-10 relative">
              <span>{option.text}</span>
            </div>
            <span className="z-10 relative pr-4">{option.percentage}%</span>
            <div
              className="absolute left-0.5 top-0.5 h-[94%] bg-orange-100 transition-all duration-500 rounded-[10.5px]"
              style={{ width: `${option.percentage - 0.4}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
