import { Title } from '@ui/components/atoms';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FanProfileBadgeItem, IFanProfileBadgeItem } from './fan-profile-badge-item';
import { FanProfileBadgeLoader } from './fan-profile-badge-loader';

export interface IFanProfileBadges {
  isLoading: boolean;
  badges: IFanProfileBadgeItem[];
  hasMore: boolean;
  onNext(): void;
  onPressBadge?: (badge: IFanProfileBadgeItem) => void;
  creatorsData?: any;
}

export function FanProfileBadges({
  isLoading,
  badges,
  hasMore,
  onNext,
  onPressBadge,
  creatorsData,
}: IFanProfileBadges) {
  return (
    <div className="flex flex-col gap-10">
      <div className="rounded-t-xl bg-black lg:bg-transparent">
        <div>
          <Title
            className="py-6 text-center text-lg font-bold text-white lg:pt-0 lg:text-left lg:text-3xl lg:font-extrabold lg:text-black"
            type="l">
            Owned Badges
          </Title>
        </div>
        <div className="w-full rounded-t-xl  bg-white px-6 py-8 lg:p-0">
          {isLoading ? (
            <div className="grid w-full auto-rows-max grid-cols-2 gap-2 md:grid md:grid-cols-3 lg:gap-6 xl:grid-cols-3 2xl:grid-cols-5 2xl:gap-12">
              <FanProfileBadgeLoader />
              <FanProfileBadgeLoader />
              <FanProfileBadgeLoader className="hidden md:flex lg:hidden" />
              <FanProfileBadgeLoader className="hidden xl:flex" />
              <FanProfileBadgeLoader className="hidden 2xl:flex" />
            </div>
          ) : (
            <InfiniteScroll
              className="grid w-full auto-rows-max grid-cols-2 gap-2 md:grid-cols-3 2xl:grid-cols-5"
              dataLength={badges.length}
              next={onNext}
              hasMore={hasMore}
              loader={
                <>
                  <FanProfileBadgeLoader />
                  <FanProfileBadgeLoader />
                  <FanProfileBadgeLoader className="hidden md:flex lg:hidden" />
                  <FanProfileBadgeLoader className="hidden xl:flex" />
                  <FanProfileBadgeLoader className="hidden 2xl:flex" />
                </>
              }>
              {badges.map((badge, key) => (
                <FanProfileBadgeItem
                  key={key}
                  name={badge.name}
                  author={badge.author}
                  collaborator={badge.collaborator}
                  creatorsData={creatorsData}
                  type={badge.type}
                  color={badge.color}
                  image={badge.image}
                  badgeAmount={badge.badgeAmount}
                  badgeNumber={badge.badgeNumber}
                  platform={badge.platform}
                  onClick={() => onPressBadge?.(badge)}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
}
