'use client';

import { useEffect, useState } from 'react';
import { Avatar, LoadingSkeleton, Text } from '@ui/components/atoms';
import { Badge } from '../badge';
import { SourceItem } from '@ui/components/moleculas';
import { BadgeSource, BadgeType } from '@ui/constants';
import { Button } from '@ui/components/ui/button';

export interface IFanProfileBadgeItem {
  type: BadgeType;
  image: string;
  color: string;
  name: string;
  collaborator?: string;
  author?: string;
  creatorsData?: any;
  badgeNumber: number;
  badgeAmount: number;
  platform: BadgeSource;
  onClick?: VoidFunction;
}

export function FanProfileBadgeItem({
  type,
  image,
  color,
  name,
  badgeAmount,
  badgeNumber,
  platform,
  onClick,
  author,
  creatorsData,
  collaborator,
}: IFanProfileBadgeItem) {
  const [collaborationCreatorsAvatars, setCollaborationCreatorsAvatars] = useState<
    (string | undefined)[]
  >([]);
  useEffect(() => {
    if (!collaborator) {
      return;
    }

    if (creatorsData?.isCreatorSuccess) {
      const creator1 = creatorsData?.creators?.find((creator: any) => creator?.id === author);
      const creator2 = creatorsData?.creators?.find((creator: any) => creator?.id === collaborator);
      setCollaborationCreatorsAvatars([creator1?.avatar, creator2?.avatar]);
    }
  }, [creatorsData?.isCreatorSuccess, collaborator]);

  return (
    <div
      className="bg-bgGrey hover:bg-secondaryHover cursor-pointer flex max-w-xs flex-col items-center gap-2 overflow-x-hidden rounded-lg p-6 lg:pb-4"
      onClick={onClick}>
      <div className="flex h-8 w-full justify-start">
        {!collaborator ? null : !creatorsData?.isCreatorSuccess ? (
          <>
            <LoadingSkeleton className="h-8 w-8 rounded-full" />
            <LoadingSkeleton className="-ml-3 h-8 w-8 rounded-full" />
          </>
        ) : (
          <div className="flex">
            {collaborationCreatorsAvatars?.map((avatar, index) => (
              <Avatar
                key={index}
                size="s"
                src={avatar}
                wrapperClassName={index > 0 ? '-ml-3' : ''}
              />
            ))}
          </div>
        )}
      </div>
      <Button className="hover:bg-secondaryHover flex h-24 w-fit items-center bg-transparent p-2 shadow-none">
        <Badge
          type={type}
          image={image}
          color={color}
          size={'xs'}
          serialNumber={badgeNumber}
          badgeAmount={badgeAmount}
        />
      </Button>
      <div className="flex h-8 items-center lg:h-10">
        <Text
          type="s"
          className="line-clamp-1 max-w-[90px] truncate whitespace-pre-wrap text-center font-semibold lg:line-clamp-2">
          {name}
        </Text>
      </div>

      <Text type="s" className="lg:bg-input rounded-xl bg-white px-2 py-1 ">
        {badgeNumber} {type != BadgeType.Community ? `/ ${badgeAmount}` : null}
      </Text>

      {platform !== BadgeSource.Undefined && (
        <div className="bg-input hidden rounded-xl px-2 py-1 lg:block">
          <SourceItem source={platform} iconClassName="w-4 h-4" className="text-sm" />
        </div>
      )}
    </div>
  );
}
