import React from 'react';

export const LoadingSpinner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
    <radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
      <stop offset="0" stopColor="#ff600d"></stop>
      <stop offset=".3" stopColor="#ff600d" stopOpacity=".9"></stop>
      <stop offset=".6" stopColor="#ff600d" stopOpacity=".6"></stop>
      <stop offset=".8" stopColor="#ff600d" stopOpacity=".3"></stop>
      <stop offset="1" stopColor="#ff600d" stopOpacity="0"></stop>
    </radialGradient>
    <circle
      style={{ transformOrigin: 'center' }}
      fill="none"
      stroke="url(#a11)"
      strokeWidth="15"
      strokeLinecap="round"
      strokeDasharray="200 1000"
      strokeDashoffset="0"
      cx="100"
      cy="100"
      r="70">
      <animateTransform
        type="rotate"
        attributeName="transform"
        calcMode="spline"
        dur="2"
        values="360;0"
        keyTimes="0;1"
        keySplines="0 0 1 1"
        repeatCount="indefinite"></animateTransform>
    </circle>
    <circle
      style={{ transformOrigin: 'center' }}
      fill="none"
      opacity=".2"
      stroke="#ff600d"
      strokeWidth="15"
      strokeLinecap="round"
      cx="100"
      cy="100"
      r="70"></circle>
  </svg>
);
