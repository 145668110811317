import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
  AlertDialogHeader,
  AlertDialogFooter,
} from '@ui/components';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  confirmText: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonClassName?: string;
}

export const ConfirmationDialog = ({
  isOpen,
  title,
  description,
  confirmText,
  isLoading,
  onConfirm,
  onCancel,
  confirmButtonClassName,
}: ConfirmationDialogProps) => (
  <AlertDialog open={isOpen}>
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            disabled={isLoading}
            className={confirmButtonClassName}>
            {isLoading ? 'Processing...' : confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </div>
  </AlertDialog>
);
