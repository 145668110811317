import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { useDndContext, type UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useMemo } from 'react';
import { Task, TaskCard } from './task-card';
import { cva } from 'class-variance-authority';
import { Card, CardContent } from '@ui/components';
import { ScrollArea, ScrollBar } from '@ui/components/ui/scroll-area';

export interface Column {
  id: UniqueIdentifier;
  title: string;
}

export type ColumnType = 'Column';

export interface ColumnDragData {
  type: ColumnType;
  column: Column;
}

interface BoardColumnProps {
  column: Column;
  tasks: Task[];
  isOverlay?: boolean;
  onDeleteItem: (task: Task) => void;
  onInputChange: (id: UniqueIdentifier, value: string) => void;
}

export function BoardColumn({ column, tasks, onDeleteItem, onInputChange }: BoardColumnProps) {
  const tasksIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { setNodeRef, transform, transition } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    } satisfies ColumnDragData,
    attributes: {
      roleDescription: `Column: ${column.title}`,
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Card ref={setNodeRef} style={style} className="w-full border-none rounded-none shadow-none">
      <ScrollArea>
        <CardContent className="flex flex-grow flex-col gap-2 p-2">
          <SortableContext items={tasksIds}>
            {tasks.map((task) => (
              <TaskCard
                onInputChange={onInputChange}
                onDeleteItem={onDeleteItem}
                key={task.id}
                task={task}
              />
            ))}
          </SortableContext>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export function BoardContainer({ children }: { children: React.ReactNode }) {
  const dndContext = useDndContext();

  const variations = cva('px-2 md:px-0 flex lg:justify-center', {
    variants: {
      dragging: {
        default: 'snap-x snap-mandatory',
        active: 'snap-none',
      },
    },
  });

  return (
    <ScrollArea className={variations({ dragging: dndContext.active ? 'active' : 'default' })}>
      <div className="flex gap-4 items-center flex-row justify-center w-full">{children}</div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
