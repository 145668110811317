import { TNotificationFeatureType } from '@notification/service';
import { Icon } from '@ui/components';

interface Props {
  type: TNotificationFeatureType;
}
export const NotificationIconTag = ({ type }: Props) => {
  const iconMaps: Record<TNotificationFeatureType, JSX.Element> = {
    post: <Icon type="postNotification" />,
    notebook: <Icon type="gem" />,
    comment: <Icon type="mentionNotification" />,
    reply: <Icon type="mentionNotification" />,
    message: <Icon type="messageNotification" />,
    challenge: <Icon type="challenge" />,
    challenge_prize_unlocked: <Icon type="messageNotification" />, // TODO: add icon
    claim_exclusive_badge: <Icon type="challenge" />,
  };
  return <div style={{ marginLeft: -14 }}>{iconMaps[type]}</div>;
};
